.myRatingHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myRatCity {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.myRatCity p {
  color: var(--neutral-pure-white, #fff);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin: 0;
}

.ratingProfilePic {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.ratingProfilePic img {
  height: 36px;
  border: 1px solid #008bb2;
  border-radius: 20px;
}

.ratingHeadLogo img {
  margin-right: 64px;
}

.logOutContainer {
  background: var(--Neutral-Pure-White, #fff);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.08);
  position: absolute;
  border-radius: 4px;
  padding: 5px 10px;
  width: 8%;
  right: 1%;
  z-index: 1000;
}

.logoutText {
  display: flex;
  gap: 5px;
}

.css-1t6c9ts button:hover {
  color: #008bb2;
  border-bottom: 1px solid #008bb2;
  background: transparent;
  border-radius: 0px;
}

.css-dgevi6-MuiButtonBase-root-MuiButton-root:active {
  color: #008bb2 !important;
  border-bottom: 1px solid #008bb2 !important;
  background: transparent !important;
  border-radius: 0px !important;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-1x7skt0 {
  background-color: transparent !important;
  box-shadow: none !important;
}
