.adminHead {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  padding: 12px 32px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}

.adminTopBar .rightBorder {
  display: flex;
  border-right: 2px solid #1d1d1b;
  height: 4vh;
  margin-left: 1rem;
  margin-right: 1rem;
  opacity: 0.2;
}

.profileImg {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
}

.profileImg img {
  height: 36px;
  border: 1px solid #008bb2;
  border-radius: 20px;
}

.adminLogOutContainer {
  background: var(--Neutral-Pure-White, #fff);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.08);
  position: absolute;
  border-radius: 4px;
  padding: 5px 10px;
  width: 7%;
  top: 12%;
  right: 3%;
}

.logoutInner {
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.adminLogOutContainer p {
  color: var(--neutral-charcoal-black, #1d1d1b);
  text-align: left;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.adminTopBar {
  display: flex;
  align-items: center;
}

.profileImg .selectProfileCon .MuiSelect-select {
  color: var(--neutral-charcoal-black, #1d1d1b);
  text-overflow: ellipsis;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-stroke-width: 0.2px;
  margin: 0;
}

.profileImg .selectProfileCon .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: #1d1d1b !important;
}

.logoutTextRow {
  display: flex;
  gap: 5px;
}
