.indicationMsgContainer {
  background-color: var(--Neutral-Pure-White, #fff);
  width: 172px;
  height: 124px;
}

.indicationIcon {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

.msgTitleCon p {
  margin: 0;
}
.msgTitleCon ul li {
  margin: 0;
}

/* Notifications */

.notifiIconStyle {
  width: 20px;
  position: relative;
}

.notificationContainer {
  z-index: 22;
  width: 40%;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.08);
  padding: 20px 0px;
  border-radius: 12px;
  top: 120%;
  right: 1%;
  max-width: 400px;
}

.notificationHeader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px;
}

.markAsReadRow {
  display: flex;
  justify-content: space-between;
}

.unreadRow {
  display: flex;
  gap: 20px;
}

.markAsReadRow h1 {
  color: var(--neutral-charcoal-black, #1d1d1b);
  text-align: center;
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 1px;
}

.markAsReadRow h2 {
  color: var(--neutral-charcoal-black, #1d1d1b);
  text-align: center;
  font-family: Uniform;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
  text-decoration: underline;
}

.notifiTextContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 20px;
}

.notifiBoxsection {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
}

.notificationTextBox {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 8px 0px;
}

.notificationContainer .bottomBorder {
  width: 100%;
  border: 1px solid #1d1d1b;
  opacity: 0.1;
  margin-top: 15px;
  margin-bottom: 0px;
}

.notificationBy {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.notifiByRightBorder {
  display: flex;
  border-right: 2px solid #000000;
  height: 3vh;
  opacity: 0.2;
}

.notificationTextBox .notifiText {
  color: var(--neutral-charcoal-black, #4a4a49);
  font-family: Uniform;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.1px;
}

.notificationTextBox p {
  width: max-content;
  color: var(--neutral-charcoal-black, #4a4a49) !important;
  font-family: Uniform;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.1px;
}

.notificationTextBox span {
  color: var(--neutral-charcoal-black, #979796);
  font-family: Uniform;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.1px;
}

.unreadIcomStyle {
  width: 12px;
  height: 12px;
}

.unreadButton {
  background-color: transparent;
  border: 1px solid #000000;
  color: #000000;
  font-size: 12px;
  padding: 3px 6px;
  cursor: pointer;
  border-radius: 5px;
}

.notifiArrowIcon {
  position: absolute;
  top: -5%;
  right: 50px;
  width: 30px;
  height: 30px;
}
