.auditOrgBoundries {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--XL, 24px);
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
}

.auditOrgBounInfoSec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.auditOrgEquityShare {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
}

.auditOrgListDownSec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.auditWhichConsiText h2 {
  color: var(--Neutral-Charcoal-Black-500, #1d1d1b);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
}

.auditOrgListDownSec h1 {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.3px;
}

.auditOrgListDownSec .auditListDown {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
}

.auditOrgcontrolSec {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  background: #f8f8f8;
}

.auditOrgcontrolSec h2 {
  color: var(--Primary-Aqua-Blue-900, #003a4b);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
}

.auditOrgFacilityRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}

.auditOrgFacilityCol1 {
  width: 25%;
}

.auditOrgFacilityCol2 {
  width: 75%;
}

.auditOrgFacilityCol2 p {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.3px;
}

.orgFacilityRow h1 {
  color: var(--Neutral-Charcoal-Black-400, #4a4a49);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
}

.orgFacilityRow p {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
}

.auditOrgcontrolSec .orgFacilityRow h1 {
  color: var(--Neutral-Charcoal-Black-400, #4a4a49);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
}

.auditOrgcontrolSec .orgFacilityRow p {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.auditOrgPerInSec {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  gap: var(--M, 12px) var(--CornerRadius-CornerRadius_Large, 20px);
  align-self: stretch;
  flex-wrap: wrap;
}

.auditOrgPerInCol1 {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.auditOrgPerInCol2 {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.auditOrgPerCol1 {
  width: 70%;
}

.auditOrgPerCol2 {
  width: 30%;
}

.auditOrgPerCol2 p {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.5px;
}

.auditOrgPerInSec .auditOrgPerCol1 h1 {
  color: var(--Neutral-Charcoal-Black-400, #1d1d1b);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  -webkit-text-stroke-width: 0.3px;
}

.auditOrgPerInSec .auditOrgPerCol1 p {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.auditRepoCmpnySec {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--M, 12px);
  align-self: stretch;
}

.auditRepoCmpnySec h1 {
  color: var(--Neutral-Charcoal-Black-500, #1d1d1b);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
}
