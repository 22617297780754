.dashboardSection {
  display: flex;
  background: linear-gradient(175deg, #00141a -18.79%, #003a4b 102.02%);
  height: 100%;
  width: 100%;
  color: white;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
}
.dashtitleDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 1.5rem 0;
}
.dashUl {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.dashUl li {
  color: var(--neutral-pure-white, #fff);
  text-align: center;
  font-family: Uniform;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin-left: 1rem;
}
.logoSec {
  display: flex;
  flex-direction: row;
}
.logoSec img {
  width: 93px;
}
.dashProfil {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.dashProfil p {
  color: var(--primary-aqua-blue-100, #b0dbe7);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.city {
  display: flex;
  align-items: center;
}
.rightBorder {
  display: flex;
  border-right: 1px solid #ffffff;
  height: 3vh;
  margin-left: 1rem;
  margin-right: 1rem;
  opacity: 0.2;
}
.city p {
  margin-right: 10px;
}
.city img {
  width: 100%;
}
.profileImg img {
  height: 36px;
  border: 1px solid #008bb2;
  border-radius: 20px;
}
.dashboardInner {
  border-radius: 8px;
  background-color: #ffffff;
  /* height: 80vh; */
  /* height: 72vh; */
  width: 90%;
  padding: 40px 40px;
}

.dashInnerSec {
  padding: 40px 40px 80px 40px !important;
}

.dashCurrentStatus {
  margin-top: 32px;
  display: flex;
  gap: 32px;
}

.welcome {
  color: #4a4a49;
  font-family: Uniform;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.welcomeDiv {
  padding: 0;
  margin-bottom: 32px;
}
.userName {
  color: #003a4b;
  font-family: Uniform;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  text-transform: capitalize;
}
.dashTimeContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px #e8e8e8;
  padding: 24px 0;
}
.ratingStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  width: 97%;
}
.ratingCo1 {
  width: 50%;
  display: flex;
  align-items: center;
}
.ratingCo2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ratingCo1 p {
  color: #003a4b;
  text-align: center;
  font-family: Uniform;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  margin: 0;
}
.updateReqBtn:hover {
  background-color: none;
  color: none;
}

.updateReqBtn {
  height: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.updateReqBtn p {
  font-size: 13px;
}

.updateReqBtn button {
  padding: 8px 6px;
}

.updateBtn {
  display: flex;
  padding: 8px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #008bb2;
  z-index: 10;
}
.updateBtn p {
  padding: 0px;
  font-size: 13px;
  font-weight: 800;
  text-align: center !important;
}
.updateBtn img {
  width: 10%;
}

.topbg {
  position: absolute;
  top: 66px;
  right: 42%;
}
.whteTest button {
  color: white !important;
}

.rightbg {
  position: absolute;
  top: 11%;
  right: 0;
}
.declaration {
  color: #1d1d1b;
  font-size: 15px;
  font-weight: 400;
  padding: 0 24px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 24px;
}
.dashboardCopy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  width: 90%;
}

.dashboardCopy p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
}

.LogOutContainer {
  cursor: pointer;
  background-color: #003a4b;
  position: absolute;
  border-radius: 8px;
  padding: 0px 19px;
  width: 12%;
  top: 10%;
  right: 6%;
}
